const api = {
  // host: "http://47.93.25.207:8085", //线上服务器域名   阿里
  host: "https://www.196116.com", //线上服务器域名   公司
  // host: "http://localhost:8085",
  universal: "/kilala/universal",
  countUniversal: "/kilala/countUniversal",
  selectUniversal: "/kilala/selectUniversal",
  ZKselectUniversal: "/selectUniversal",
  getLatin: "/getLatin",
  liWeiHai: "/liWeiHai",
  directory: "/directory",
  softwareKey: "/softwareKey",
  collections: "/collections",
  user: "/kilala/user",
  uploadFile: "/uploadFile",
  uploadTo_02: "/uploadTo_02",
  dangAnGuan: "/dangAnGuan",
  getArchivesPhotos: "/getArchivesPhotos",
};

export default api;
